import React, { useEffect, useMemo, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Topbar from "../top-bar/Topbar";
import { Menu } from "../sidebar-menu/SidebarMenu";
import Warningbar from "../warning-bar/Warningbar";
import { Container, Wrapper, RouteContainer } from "./Layout.styles";
import { useSelector } from "react-redux";
import {
  getIsUserEmailVerified,
  isUserAuthenticatedandUsageLoaded,
} from "selectors/UserSelectors";
import { ACCOUNT_STATUS_TYPES } from "constants/AccountTypeConstants";
import { getAccountType } from "selectors/AccountSelectors";
import { loadingLicenseDetails } from "selectors/LicenseSelectors";
import { NO_MENU_ROUTES } from "./constants";

export type OutletContextType = {
  setFixedSelectors: (selectors: string[]) => () => void;
};

function Layout() {
  const isUserEmailVerified = useSelector(getIsUserEmailVerified);
  const accountType = useSelector(getAccountType);
  const isLicenseLoading = useSelector(loadingLicenseDetails);
  const isAuthenticated = useSelector(isUserAuthenticatedandUsageLoaded);
  const ref = useRef<HTMLDivElement>(null);

  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    ref.current?.scrollTo(0, 0);
  }, [pathname]);

  const canShowBanner = useMemo(() => {
    return (
      accountType !== ACCOUNT_STATUS_TYPES.FREE &&
      accountType !== ACCOUNT_STATUS_TYPES.NOT_AVAILABLE &&
      accountType !== ACCOUNT_STATUS_TYPES.PAID_ACTIVE &&
      accountType !== ACCOUNT_STATUS_TYPES.PENDING_UPDATE
    );
  }, [accountType]);

  const showMenu = useMemo(() => {
    return isUserEmailVerified && !NO_MENU_ROUTES.includes(pathname);
  }, [pathname, isUserEmailVerified]);

  return (
    <div className="App">
      <Container>
        {canShowBanner && !isLicenseLoading && <Warningbar />}
        {isAuthenticated && <Topbar />}
        <Wrapper>
          {showMenu && <Menu />}
          <div className="relative w-full">
            <RouteContainer
              canShowBanner={canShowBanner}
              fullscreen={!isUserEmailVerified}
              ref={ref}
            >
              <Outlet />
            </RouteContainer>
          </div>
        </Wrapper>
      </Container>
    </div>
  );
}

export default Layout;
